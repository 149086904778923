.card .container {
  padding: 1em;
}

.card {
  margin-top: 1em;
  margin-bottom: 1em;
}

form {
  padding-bottom: 2em;
}

.authorized-rep-line {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.is-invalid.btn-group {
  border-style: solid;
  border-width: 2px;
  border-color: #dc3545;
  border-radius: 0.25rem;
}
