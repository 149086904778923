/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.spinner-indicator {
  padding-top: 20px;
  padding-bottom: 20px;
}

.spinner-indicator .spinner-indicator-text {
  font-size: x-large;
}

.branding-logo {
  max-width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.invalid-input-icon {
  color: #dc3545;
  margin-left: 5px;
}

.validation-warning-message {
  color: #dc3545;
  font-size: larger;
}

/* Plan type beneficiary section */
.plan-type-row .plan-type-help-column {
  padding-right: 0px;
}

.plan-type-row .plan-type-response-column {
  padding-left: 0px;
}

.plan-type-row .plan-type-help-icon {
  padding: 10px;
}

.loading-overlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(204, 204, 204, 0.5);
  width: 100%;
  height: 100%;
  /* place over top of everything else */
  z-index: 100;
  justify-content: center;
  align-items: center;
}

/* full page message formatting */
.full-page-message .logo {
  padding: 3em;
}

.full-page-message .message-body {
  font-size: larger;
  padding: 1em;
}

.full-page-message .message-body p {
  max-width: 50em;
  margin-left: auto;
  margin-right: auto;
}

/* yes/no switch */
/* space between icon and text for yes/no display */
.yes-no-selection-display .icon {
  padding-right: 5px;
}

/* Override the color of the active button on yes/no togglers */
.yes-no-switch .btn.active, .yes-no-switch .btn:hover {
  color: #fff!important;
  background-color: #079ed3!important;
  border-color: #079ed3!important;
}

.window-corner-text {
  position: absolute;
  bottom: 0;
  right: 0;
  color: grey;
  padding: 0 5px 0 5px;
}

.telephonic-script {
  font-size: 1.2em;
  line-height: 1.8;
}